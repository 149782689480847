import React from "react";
import "./About.scss"
import { servicesList, servDescription, textContainer } from "../../data";
import ActionPanel from "../../components/ActionPanel/ActionPanel";
import ContactForm from "../../components/ContactForm/ContactForm";
import back_cover from "../../assets/background/back_cover.mp4";

const About = () => {
  return (
    <div className="about-page">
      <div className="about-cover">
        <video className="about-video-rest" autoPlay muted loop playsInline>
          <source src={back_cover} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <ul className="about-segment-border">
        <li className="segment-field ">
          <div className="about-title">{textContainer[0].title}</div>
          <div className="text-contain">{textContainer[0].textContent}</div>
        </li>
        <li className="segment-field  reverse">
          <div className="about-title">{servDescription.title}</div>
        </li>
        <li className="segment-field  ">
          <div className="text-contain">{servDescription.desc1}</div>

          <ul className="cmpny-serv-options">
            {servicesList.map((panel, index) => (
              <li className="serv_option_item" key={index}>
                <ActionPanel
                  link={panel.link}
                  title={panel.name}
                  text={panel.description}
                  img={panel.img}
                />
              </li>
            ))}
          </ul>

          <div className="text-contain extra">{servDescription.desc2}</div>
        </li>
      </ul>
      <ContactForm />
    </div>
  );
};

export default About;
