import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.scss';
const ContactForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            from_name: `${firstName} ${lastName}`, 
            message: message,
            reply_to: email 
        };

        emailjs.send('service_eh8bsc8', 'template_3qpqve7', templateParams, 'bqCognPp3dwI-zn31')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
            }, (err) => {
                console.error('FAILED...', err);
                alert('Failed to send message, please try again.');
            });

        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
    };

    return (
      <div className="contact-form">
        <div className='contact-form-title'>
          <h2>Contact us</h2>
          
        </div>
        <div className='forms'>
          
          <form onSubmit={handleSubmit}>
            <div className='contact-input'>
              <label>First name*</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className='contact-input'>
              <label>Last name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className='contact-input'>
              <label>Email*</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='contact-input'>
              <label>What can we help you with?</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button type="submit"><p>Submit</p></button>
          </form>
        </div>
      </div>
    );
};

export default ContactForm;
