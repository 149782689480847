import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAddress, setUserAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const login = async () => {
    if (window.ethereum) {
      setLoading(true);
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const address = accounts[0];
        setUserAddress(address);

        const message = `Sign this message to authenticate with your address: ${address}`;
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [message, address],
        });

        console.log("address:",address);
        console.log("signature;",signature)
        console.log("message:",message)

        const response = await fetch("http://localhost:8000/web3_auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ address, signature, message }),
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Authentication successful:", data);
          setIsAuthenticated(true);
        } else {
          console.error("Backend authentication failed:", response.statusText);
        }
      } catch (error) {
        console.error("MetaMask authentication failed:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("MetaMask is not installed.");
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userAddress, login, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
