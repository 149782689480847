import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const hostURL = "https://nftbazar-backend-104732163593.europe-west9.run.app/";
const artistURL = `${hostURL}artist/file/`;
const previewURL = `${hostURL}nft/prevfile/`;
const originalURL = `${hostURL}nft/file/`;

const formatName = (name) => {
  return name.replace(/ /g, "_").toLowerCase();
};

const parseName = (name) => {
  return name ? name.replace(/_/g, " ").toLowerCase() : "";
};

const useToGetString = (element, link) => {
  const [fetchStroke, setFetchStroke] = useState(null);

  useEffect(() => {
    if (!element || !link) return;

    const getStrokes = async () => {
      try {
        const fetchPromises = Array.isArray(element)
          ? element.map(id => axios.get(`${hostURL}${link}/${id}`).then(response => response.data))
          : [axios.get(`${hostURL}${link}/${element}`).then(response => response.data)];

        const results = await Promise.all(fetchPromises);
        // console.log(`${hostURL}${link}/${element}`);
        setFetchStroke(Array.isArray(element) ? results : results[0]);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    getStrokes();
  }, [element, link]);

  return fetchStroke;
};

const useToGetItemData = (id, link) => {
  const [artistItem, setArtistItem] = useState(null);

  useEffect(() => {
    if (!id || !link) return;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${hostURL}${link}/${id}`);
        setArtistItem(response.data);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, link]);

  return { artistItem };
};

const useToGetData = (route) => {
  const [blockItems, setBlockItems] = useState(null);

  useEffect(() => {
    if (!route) return;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${hostURL}${route}`);
        setBlockItems(response.data);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [route]);

  return blockItems;
};

const useToFindDirection = (link, searchTerm) => {
  const blockItems = useToGetData(link);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (!blockItems || !searchTerm) return;

    let foundItem = null;

    const isId = !isNaN(parseInt(searchTerm, 10));
    if (isId) {
      foundItem = blockItems.find(
        (element) => element.id === parseInt(searchTerm, 10)
      );
    } else {
      const parsedName = parseName(searchTerm);
      foundItem = blockItems.find(
        (element) => element.name && parseName(element.name) === parsedName
      );
    }

    setItem(foundItem || null);
  }, [blockItems, searchTerm]);

  return item;
};

const useToList = (route, type) => {
  const [fetchList, setFetchList] = useState([]);
  const structures = useToGetData(route);

  useEffect(() => {
    if (!structures || !type) return;

    const typeList = structures.flatMap((item) =>
      Array.isArray(item[type]) ? item[type] : [item[type]]
    );

    
    const uniqueList = Array.from(
      new Set(typeList.map(item => item.trim().toLowerCase()))
    );
    
    setFetchList(uniqueList);
  }, [type, structures]);

  return fetchList;
};


const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export {
  formatName,
  useToGetItemData,
  useToGetData,
  useToFindDirection,
  useToList,
  ScrollToTop,
  useToGetString,
  hostURL,
  artistURL,
  previewURL,
  originalURL,
};
