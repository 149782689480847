import { Route, Routes, useLocation,useParams } from "react-router-dom";
import { useState, useEffect, Suspense, lazy } from "react";
import "./App.scss";
import { artWorksData, artistData } from "./data";
import { useToFindDirection, ScrollToTop } from "./data_requests";
import Preloader from "./components/Preloader/Preloader";
import Footer from "./components/Footer/Footer";
import Legal from "./routes/Legacy/Legal";
import About from "./routes/About/About";



const Home = lazy(() => import("./routes/Home/Home"));
const NavBar = lazy(() => import("./components/NavBar/NavBar"));
const MarketPage = lazy(() => import("./routes/MarketPage/MarketPage"));
const User = lazy(() => import("./routes/User/User"));
const Art = lazy(() => import("./routes/item/Art/Art"));
const Artist = lazy(() => import("./routes/item/Artist/Artist"));
const Admin = lazy(() => import("./admin/Admin"));
const NotFound = lazy(() => import("./routes/NotFound/NotFound"));
const PhyArt = lazy(() => import("./routes/item/Art/PhyArt"));

function App() {
  const location = useLocation();

  const isAccountPage =
    location.pathname === "/account" || location.pathname === "/admin" || location.pathname === "*";

  return (
    <div className="App">
      <Suspense fallback={<Preloader />}>
        {!isAccountPage && <NavBar />}
        <ScrollToTop />
        <Routes>
          <Route path="/aGVsbG8gd29ybGQh" element={<Admin />} />
          <Route
            path="/"
            element={<Home dataMain={artWorksData} dataArtist={artistData} />}
          />
          <Route path="/nfts" element={<MarketPage marketType="nft" />} />
          <Route path="/artworks" element={<MarketPage marketType="artwork" />} />
          <Route path="/artist" element={<MarketPage marketType="artist" />} />
          <Route path="/nft/:name" element={<DataWrapper type="nft" Component={Art} />} />
          <Route path="/artist/:name" element={<DataWrapper type="artist" Component={Artist} />} />
          <Route path="/artwork/:name" element={<DataWrapper type="artwork" Component={PhyArt} />} />
          <Route path="/about" element={<About/>} />
          <Route path="/profile" element={<User />} />
          <Route path="/legal/:section" element={<Legal />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}


function DataWrapper({ type, Component }) {
  const [loading, setLoading] = useState(true);
  const { name } = useParams();
  const fetchData = useToFindDirection(type, name);

  useEffect(() => {
    if (fetchData) {
      setLoading(false);
    }
  }, [fetchData]);

  if (loading) {
    return <Preloader />;
  }

  return <Component data={fetchData} />;
}

export default App;
