import React, { useState } from "react";

import "./ActionPanel.scss";

const ActionPanel = ({ title, text, img, link, ...attr }) => {
  const [isPanelActive, setActive] = useState(true);

  const handlePanel = () => {
    setActive(!isPanelActive);
  };

  const imgStyle = {
    background: `url(${img})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="ActionPanel">
      <div className="action-container">
        {isPanelActive ? (
          <>
            <div className="a-img-item" style={imgStyle} />
            <div className="a-panel-title">{title}</div>
          </>
        ) : (
          <>
            <div className="a-panel-text">{text}</div>
          </>
        )}
      </div>
      <div className="btns">
        <div className={`flip ${link ? "separ" : ""}`} onClick={handlePanel}>
          <p>{isPanelActive ? "learn more" : "hide"}</p>
        </div>
        {link && (
          <div className="flip">
            <a href="https://funooni.com">
              <p className="item-fl">Move to</p>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionPanel;
